//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DataTable from "@/components/DataTable/DataTable";
import { getEntityTableColumns } from "@/utils/enums";
export default {
  components: {
    DataTable
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    reportSettings: {
      type: Object,
      required: true
    },
    routeParams: {
      type: Object,
      required: true
    }
  },
  methods: {
    refresh() {
      this.$refs.table.refresh();
    },
    tableLoader({
      query
    }) {
      return this.$store.dispatch("list/getListBrands", {
        ...query,
        ...this.reportSettings
      });
    }
  },
  computed: {
    columns() {
      return [{
        title: "Бренд",
        position: 1,
        show: true,
        name: "brand",
        width: 440,
        filter: "text",
        type: "link",
        getLink: item => {
          return {
            route: {
              name: "BrandInfo",
              params: {
                ...this.routeParams,
                b_id: item.b_id
              },
              query: {
                date: this.reportSettings.date,
                date2: this.reportSettings.date2,
                fbs: this.reportSettings.fbs
              }
            },
            text: item.brand,
            target: "_blank"
          };
        }
      }, ...getEntityTableColumns("brand", 2),
      //!&: 2022-05-21
      {
        title: "Впервые",
        show: true,
        name: "date",
        width: 100,
        filter: "dateRange",
        type: "date"
      }, {
        title: "Последний раз",
        show: true,
        name: "date2",
        width: 110,
        filter: "dateRange",
        type: "date"
      }];
    }
  }
};