//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DataTable from "@/components/DataTable/DataTable";
import CategoryPath from "@/components/Category/Path";
import { getEntityTableColumns } from "@/utils/enums";
export default {
  components: {
    DataTable,
    CategoryPath
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    reportSettings: {
      type: Object,
      required: true
    },
    routeParams: {
      type: Object,
      required: true
    }
  },
  methods: {
    refresh() {
      this.$refs.table.refresh();
    },
    tableLoader({
      query
    }) {
      return this.$store.dispatch("list/getListCategories", {
        ...query,
        ...this.reportSettings
      });
    }
  },
  computed: {
    columns() {
      return [{
        title: "Категория",
        position: 1,
        show: true,
        name: "c_name_path",
        width: 440,
        filter: "text",
        type: "slot"
      }, ...getEntityTableColumns("category", 2)];
    }
  }
};