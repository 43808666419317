/* unplugin-vue-components disabled */var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('data-table',_vm._b({ref:"table",attrs:{"table-id":"list_categories","id":_vm.id,"columns":_vm.columns,"loader":_vm.tableLoader,"loaderConfig":_vm.reportSettings,"item-id":"c_id","select-action":"remove"},on:{"selectAction":function($event){return _vm.$emit('selectAction', $event)}},scopedSlots:_vm._u([{key:"c_name_path",fn:function(ref){
var item = ref.item;
var categories = ref.categories;
return [_c('category-path',{attrs:{"block":"","path":item.c_id_path,"categories":categories,"route-params":_vm.routeParams,"target":"_blank","route-query":{
                date: _vm.reportSettings.date,
                date2: _vm.reportSettings.date2,
                fbs: _vm.reportSettings.fbs,
            }}})]}}])},'data-table',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }